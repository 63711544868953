import { mapGetters, mapMutations, mapActions} from 'vuex';
import datetime from 'vuejs-datetimepicker'
import lotProductPopup from './movementProduct/index.vue'
import removeConfirm from '@/components/popups/remove-confirm/index.vue'


export default {
    props: ['popupType', 'newProduct'],
    components: {
        datetime,
        lotProductPopup,
        removeConfirm
    },
    data() {
        return {
            form: {
                donor_warehouse_id:  null,
                recipient_warehouse_id:  null,
                date: new Date(),
                warehouse: null,
                products: [],
            },
            showLotPopup: false,
            showRemoveConfirm: false,
            selectedItem: null,
            fields: [
                {
                    key: 'product_id',
                    label: 'id',
                },
                {
                    key: 'title',
                    label: 'Товар',
                },
                {
                    key: 'quantity',
                    label: 'Кол-во',
                },
            ],
            items:[],
            productsCount: 0,
        }
    },
    computed: {
        ...mapGetters({
            transposition: 'lot/transposition',
            warehouses: 'warehouse/warehouses',
            provisioners: 'warehouse/provisioners',
            products: 'catalog/products',
            product: 'catalog/product'
        }),
    },
    watch:{
        newProduct(e){
            this.items.push(e)
            if(this.form.products){
                this.form.products.push(e)
            }else{
                this.form.products = [];
                this.form.products.push(e)
            }
        },
        transposition(e){
            this.form = {
                donor_warehouse_id:  e.donor_warehouse_id,
                recipient_warehouse_id:  e.recipient_warehouse_id
            }
            e.products.map(item=>{
                let product = e.formatted_products.filter(productItem => productItem.product_id === item.product_id);
                product = product[0] ? product[0] : null;
                this.items.push({
                    product_id: item.product_id,
                    title: product.title,
                    quantity: item.quantity,
                })

                this.productsCount += item.quantity;
            });
        },
        item(e) {
            this.showProduct(e)
        },
    },
    created() {
      this.getWarehouses()
      this.getProvisioners()
      this.getProducts()
    },
    methods: {
        appendProduct(payload) {
            this.form.products.push(payload)
            this.showLotPopup = false
        },
        addProductClick() {
            this.showLotPopup = true;
        },
        closePopup() {
            this.$emit('closePopup')
        },
        openPopupSearchProduct(){
            this.toggleShowAddProductPopUp(true);
        },
        sendLot() {
            const data = {
                donor_warehouse_id: this.form.donor_warehouse_id,
                recipient_warehouse_id: this.form.recipient_warehouse_id,
                products: this.form.products.map(item => {
                    return {
                        product_id: item.product_id,
                        quantity: +item.quantity
                    }
                })
            }
            if(this.transposition){
                const payload = {
                    id: this.lot.id,
                    data: data
                }
                this.$emit('changeLot', payload)
            }else{
                this.$emit('sendLot', data)
            }
        },
        selectItem(item) {
            this.selectedItem = item[0]
        },
        deleteLotProduct() {
            const index = this.form.products
                .findIndex(
                    item => item.lot_id === this.selectedItem.lot_id && item.product.id === this.selectedItem.product.id && item.buy_price === this.selectedItem.buy_price
                )
            if (index < 0) {
                return
            }
            this.removeLotProduct(this.selectedItem.lot_id)
            this.form.products.splice(index, 1)
            this.showRemoveConfirm = false
        },
        ...mapActions({
            getWarehouses: 'warehouse/getWarehouses' ,
            getProvisioners: 'warehouse/getProvisioners',
            getProducts: 'catalog/getProducts',
            showProduct: 'catalog/showProduct',
            removeLotProduct: 'lot/removeLotProduct'
        }),
        ...mapMutations({
            toggleShowAddProductPopUp: 'config/toggleShowAddProductPopUp',
            changeTransposition: 'lot/changeTransposition',
            changeProduct: 'catalog/changeProduct'
        })
    },
    destroyed() {
        this.changeTransposition(null);
        this.changeProduct(null)
    }
}
