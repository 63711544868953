import {mapGetters, mapActions} from 'vuex';

export default {
    props: ['popupType'],
    data() {
        return {
            form: {
                buy_quantity: null,
                buy_price: null,
                less_quantity: null
            },
            // hasImage: false,
            category_id: null,
            product_id: null,
            product_price: null,
        }
    },
    computed: {
        ...mapGetters({
            products: 'catalog/products',
            product: 'catalog/product',
            productCategories: 'catalog/productCategories'
        }),
    },
    watch: {
        category_id(e) {
           this.getProductsByCategory(e)
        },
        product_id(e) {
            this.showProduct(e)
        },
        product(e) {
           this.product_id = e.id
           this.product_price = e.sale_price
        }
    },
    created() {
        this.getProductCategories()
    },
    methods: {
        createProduct() {
            const payload = {
                buy_price: this.form.buy_price,
                buy_quantity: +this.form.buy_quantity,
                less_quantity: +this.form.less_quantity,
                product: {
                    id: this.product.id,
                    sku: this.product.sku,
                    isbn: this.product.isbn,
                    title: this.product.title
                },
                product_id: this.product.id
            }
            this.$emit('createProduct', payload)
        },
        closePopup() {
            this.$emit('closePopup')
        },
        ...mapActions({
            getProductCategories: 'catalog/getProductCategories',
            getProductsByCategory: 'catalog/getProductsByCategory',
            showProduct: 'catalog/showProduct'
        }),
        // ...mapMutations({
        //     changeLot: 'lot/changeLot'
        // }),
    },
    destroyed() {
        // this.changeLot(null);
    }
}